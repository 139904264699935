<template>
  <section>
    <div class="richtext">
      <h2 style="text-align: left">Dosing pumps used in surface treatment</h2>
      <p style="text-align: left"><br /></p>
      <p style="text-align: left">
        A surface treatment&nbsp;or electroplating and coating is an act whereby
        a coating is applied to a surface. The purpose of surface treatment is
        decorating the appearance, protect the surface against (harmful)
        external influences (corrosion) or improving adhesion in the coating
        process. Surface treatment can also be applied to change material
        qualities. With metals, surface treatment can be realized by chemical
        treatment. NEWDOSE Dosing pumps have good experiences in this
        industry,&nbsp; among other things, with pumps for circulation and
        filter the liquids in the chemical baths.
      </p>
      <p><br /></p>
      <p>
        For example, the automatic production of plastic electroplating is
        widely used in automobile decorative parts, mobile phone decorative
        arts, signage, and other industries. with the expansion of the
        application to the electroplating treatment of ABSPC plastic products.
        Firstly, a metal layer is obtained by a special treatment method on the
        basis surface of the plastic, which has the advantages of both plastic
        and metal, that is, the lightness of plastic and the luster of metal.
      </p>
      <p><br /></p>
      <p style="text-align: center">
        <img
          src="/uploads/image/20201123/Surface_treatment.jpg"
          title="Surface treatment"
          alt="Surface treatment"
          width="907"
          height="595"
          border="0"
          vspace="0"
          style="width: 907px; height: 595px"
        />
      </p>
      <p><br /></p>
      <p>
        The metalized plastics overcome some defects and shortcomings of plastic
        itself. It has a lot of advantages, such as good solvent resistance,
        corrosion resistance, light resistance, thermal conductivity, with a
        good appearance of metal, coating hardness high characteristics.
      </p>
      <p><br /></p>
      <p>
        For the electroplating production line, the diaphragm metering pump,
        chemical liquid filter, electroplating filter, and wastewater treatment
        equipment should be designed and manufactured, which continuously
        promotes the development of the surface treatment industry.
      </p>
      <p><br /></p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Surface Treatment",
};
</script>